exports.samples = [
    {
        id: 1,
        slug: 'sample-essay-literature',
        type: 'Essay',
        title: 'Character Analysis of Charlie from The Perks of Being a Wallflower',
        discipline: 'Literature',
        format: 'MLA',
        sources: 1,
        level: {
            value: 'highschool',
            text: 'High School'
        },
        pages: 6,
        list: [
            '250+ ghostwriters (all native speakers)',
            '24/7 support & direct contact with your writer',
            '100% confidentiality'
        ],
        description: 'This sample paper is a literature essay that contains a character analysis. The work analyses Charlie, a character of The Perks of Being a Wallflower by S. Chbosky',
        instructions: 'Choose any current topic that appears problematic from an literature essay/character analysis. You can take any direction you want: animal rights, refugee rights, human rights, recent political or world events, etc.',
        fileName: 'sample_1',
        buttonText: 'Order'
    },
    {
        id: 2,
        slug: 'sample-argumentative-essay',
        type: 'Essay',
        title: 'Argumentative Essay: Why All Schools in United States Should Require Uniforms',
        discipline: 'English Composition - Essay Writing',
        format: 'Chicago Author-Date Style',
        sources: 1,
        level: {
            value: 'highschool',
            text: 'High School'
        },
        pages: 6,
        list: [
            '250+ ghostwriters (all native speakers)',
            '24/7 support & direct contact with your writer',
            '100% confidentiality'
        ],
        description: 'This sample argumentative essay discusses why schools should require students to wear uniforms. The paper follows the point-by-point discussion structure and is formatted in the Chicago author-date citation style',
        instructions: 'Write an argumentative essay on a topic of your choice. Specify your argument and follow the point-by-point discussion format.',
        fileName: 'sample_2',
        buttonText: 'Order'
    },
    {
        id: 3,
        slug: 'sample-cause-and-effect-essay',
        type: 'Essay',
        title: 'Cause and Effec Essay: The Impact of Stress',
        discipline: 'English Composition - Essay Writing',
        format: 'APA',
        sources: 2,
        level: {
            value: 'highschool',
            text: 'High School'
        },
        pages: 6,
        list: [
            '250+ ghostwriters (all native speakers)',
            '24/7 support & direct contact with your writer',
            '100% confidentiality'
        ],
        description: 'This paper is a sample of a cause and effect essay, which is a type of expository essay. The work discusses the causes of stress and what impact stress makes on students',
        instructions: 'Write an expository essay on "stress". You may choose to write one among the following types: descriptive, comparison and contrast, cause and effect, or process essay.',
        fileName: 'sample_3',
        buttonText: 'Order'
    },
    {
        id: 4,
        slug: 'sample-term-paper',
        type: 'Term Paper',
        title: 'Term Paper on Long-Term Implications of COVID-19',
        discipline: 'Research',
        format: 'Harvard',
        sources: 7,
        level: {
            value: 'college',
            text: 'College'
        },
        pages: 6,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This sample term paper discusses the causes and implications of the pandemic. The paper follows the Harvard citation style guidelines and can be used as a reference for when you need to format your term work in Harvard',
        instructions: 'Study the biggest social concerns of our society today. Choose a major dilemma to research and write a term paper on emphasizing correlated concerns: 1. When identifying a major problem, provide specific points of discussion rather than summarizing general research. 2. Synthesize the data. Do not simply reiterate previously published research data. 3. Analyze the issue and present a possible solution to the problem. ',
        fileName: 'sample_4',
        buttonText: 'Order'
    },
    {
        id: 5,
        slug: 'sample-scholarship-essay',
        type: 'Essay',
        title: 'Scholarship Essay: Discuss Yourself & Overcoming Personal Hardship',
        discipline: 'English Composition',
        format: 'APA',
        sources: 0,
        level: {
            value: 'college',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'These are 2 sample scholarship essays introducing the writer\'s personal story, personal hardship and how the writer overcame it. These are the most popular prompts for scholarship papers — get familiar with the samples to master your essay',
        instructions: 'For this requirement: Write two essays on the following: 1. Tell us about yourself; 2. Describe a personal hardship or catastrophic life event and how you overcome it. ',
        fileName: 'sample_5',
        buttonText: 'Order'
    },
    {
        id: 6,
        slug: 'sample-admission-essay',
        type: 'Admission Essay',
        title: 'Admission Essay for PostGraduate Degree',
        discipline: 'English Composition',
        format: 'APA',
        sources: 0,
        level: {
            value: 'PhD',
            text: 'PhD'
        },
        pages: 4,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This is a sample admission essay for a doctorate degree in Comparative Literature. The essay focuses on the author\'s interest in the course and the reasons why the applicant is a worthy candidate',
        instructions: 'Submit an essay of less than 1,000 words disussing your interest of the course you would like to take - what inspired you to take Comparative Literature? Additionally, elaborate your reasoning as to why you chose the university for this academic journey.',
        fileName: 'sample_6',
        buttonText: 'Order'
    },
    {
        id: 7,
        slug: 'sample-narrative-essay',
        type: 'Narrative Essay',
        title: 'Narrative Essay on How the Pandemic Affected My Family',
        discipline: 'English Composition - Essay Writing',
        format: 'MLA',
        sources: 0,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 3,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Got an assignment to write a narrative essay but don\'t know where to start? Check out this narrative paper sample on how the pandemic affected the writer\'s family to get inspired',
        instructions: 'A narrative essay is only one of the many types of essays out there. Writing a narrative essay is telling the reader a story. For this assignment, write a narrative exploring how the pandemic affected you or someone you know. Aim to keep the reader interested and make sure to paint a picture. Use strong words that perfectly fits the emotion you are trying to evoke. When writing a narrative essay, try to add a lesson or a point within it. The reader should learn something after they are finished reading your essay.',
        fileName: 'sample_7',
        buttonText: 'Order'
    },
    {
        id: 8,
        slug: 'sample-narrative-essay-apa',
        type: 'Narrative Essay',
        title: 'Narrative Essay in APA Citation Style',
        discipline: 'English Composition - Essay Writing',
        format: 'APA',
        sources: 1,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 6,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Got an assignment to write a narrative essay but don\'t know how to format it in APA properly? Use this narrative paper sample as an example to follow the APA citation style guidelines in your essay',
        instructions: 'Our lives is full of narratives, and a narrative is happening now as we speak. But, what great of a story if no one is there capable to tell it? This writing exercise will test your capability to tell a story about a topic of your choice. However, keep in mind that the most significant factor of writing a narrative essay is chronology. Be wary of the elements, characters, emotions, and the order of events of your story.',
        fileName: 'sample_8',
        buttonText: 'Order'
    },
    {
        id: 9,
        slug: 'sample-grant-essay',
        type: 'Grant Proposal',
        title: 'Grant Proposal Paper (including a Cover Letter)',
        discipline: 'Business - Grants',
        format: 'APA',
        sources: 5,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 9,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Check out this sample grand proposal paper on a project aiming to provide distance communities with access to the Internet. This example paper also contains a sample cover letter, which you can use as a reference',
        instructions: 'Grant Proposals are one of the facets of research proposals which benefits are both academic and professional. Writing a grant proposal enriches a student\'s understanding about research study, locating grants, and possibly acquring grants - to which the first step is writing a Grant Proposal. Now that you\'ve conducted research about a benefitting project, it is now time to try and acquire a grant to fund it. To practice, create a hypothetical company or organization where you will submit a grant request to. Other details can also be created to support the scenario. You Grant Proposal should at least present the following details about your project: 1. An overview; 2. Justification of the need for your project; 3. Complete, but concise description; 4. Objectives; and 5. Other significant information you wish to share that you think will help you acquire the grant. Do not forget to include a cover letter stating your purpose of sending your project brief to a potential grantor. Remember that you are only writing a proposal, hence, keep it as short as possible. A good length should be around 4-6 pages.',
        fileName: 'sample_9',
        buttonText: 'Order'
    },
    {
        id: 10,
        slug: 'sample-descriptive-essay',
        type: 'Essay',
        title: 'Descriptive Essay on Leonardo da Vinci\'s Life',
        discipline: 'English Composition',
        format: 'MLA',
        sources: 1,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This descriptive essay discusses the life of Leonardo da Vinci, from his young years to death. Check out this sample paper to learn how to write a good descriptive essay',
        instructions: 'Descriptive essays describes a subject, be it an object or a person, by appealing to the senses: sight, smell, sound, taste, touch. To write your descriptive essay, make sure to meet the following basic principles of writing one: 1. Choose your subject. 2. Pool relevant details. 3. Organize the information. 4. Paint a picture. 5. Draw a conclusion. Pay attention to the details you share more than you interpret. Organize your work to present the subject neatly, and make sure to not mix up timelines, if there is any.',
        fileName: 'sample_10',
        buttonText: 'Order'
    },
    {
        id: 11,
        slug: 'sample-compare-and-contrast-essay',
        type: 'Essay',
        title: 'Compare and Contrast Essay on Virtual vs Traditional Classes',
        discipline: 'English Composition',
        format: 'APA',
        sources: 6,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 8,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Need a sample of a compare and contrast essay? This paper compares virtual and traditional classes in a point-by-point structure. This essay can also serve as an example of a paper formatted in the APA citation style',
        instructions: 'What most people forget about comparing two objects is the importance of the main similarity between them. For instance, you cannot compare the taste of an apple to that of an orange because they are different by default. For this assignment, choose two ideas or objects under the same wavelength, and write a compare and contrast essay about them. Use a point-by-point system to explain your thoughts. Use scholarly sources to prove your arguments.',
        fileName: 'sample_11',
        buttonText: 'Order'
    },
    {
        id: 12,
        slug: 'compare-and-contrast-essay',
        type: 'Essay',
        title: 'Compare and Contrast Essay on American vs French Revolution',
        discipline: 'English Composition',
        format: 'APA',
        sources: 6,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Looking for a sample compare and contrast essay? This essay example compares the American and French Revolution to show how both events contrast in their sociopolitical contexts and outcomes',
        instructions: 'English Composition: Comparison and Contrast Essay - Your task is to choose significant phenomenon e.g. hsitorical events to compare and contrast. Use the block approach in writing your essay and do not forget to cite peer-reviewed articles. This assignment will exercise not only your analytical skills, but also your writing and researching skills.',
        fileName: 'sample_12',
        buttonText: 'Order'
    },
    {
        id: 13,
        slug: 'argumentative-essay',
        type: 'Essay',
        title: 'Argumentative Essay on The Great Gatsby',
        discipline: 'English Composition',
        format: 'MLA',
        sources: 3,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Check out a sample essay on the values reflected in F. Scott Fitzgerald\'s The Great Gatsby. This argumentative essay discusses the characters and how they differ, in addition to the novel\'s impact on American literature',
        instructions: 'Read, gather data, analyze, then write. Literary analysis can come in many forms depending on your thesis. Using the literature of your choice, draw a challenging idea which aims to meet any of the following: a. to explore (explain) b. to persuade (generate a concept) c. to argue (prove or disprove). Note that your thesis statement should tell your reader what to expect.',
        fileName: 'sample_13',
        buttonText: 'Order'
    },
    {
        id: 14,
        slug: 'sample-argumentative-essay-2',
        type: 'Essay',
        title: 'Argumentative Essay on Why GMOs Are Safe',
        discipline: 'English Composition',
        format: 'APA',
        sources: 5,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 6,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This sample argumentative essay discusses why genetically modified organisms (GMOs) are safe. The paper is formatted in the APA citation style and can be used as a reference for when you need to format your essay in APA',
        instructions: 'Is it safe to consume genetically modified organisms? Use scholarly sources to support your argument. Make sure that your thesis is clear and direct',
        fileName: 'sample_14',
        buttonText: 'Order'
    },
    {
        id: 15,
        slug: 'sample-MLA-research-paper-essay',
        type: 'MLA Research Paper',
        title: 'MLA Research Paper on Toxic Substances',
        discipline: 'Research Writing',
        format: 'MLA',
        sources: 16,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 24,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Looking for an example research paper formatted in the MLA citation style? Check out this research paper sample on toxic substances to learn the proper way of formatting your work in MLA',
        instructions: 'FUNDAMENTALS OF RESEARCH WRITING Getting a topic approved is only the beginning of research writing. The next step is visiting published materials to look for valid references for your research paper. > Compare and contrast concepts, ideas, and arguments for and against your reaearch question/s. > Conduct analysis and synthesize evidences with your own. Do not simply write a summary of empirical sources, instead offer your theories. > Cite your sources properly to avoid issues with originality. > Utilize MLA Citation Style for in-text citations, bibliography, and formatting. Topic: Toxic Substances in the Environment',
        fileName: 'sample_15',
        buttonText: 'Order'
    },
    {
        id: 16,
        slug: 'case-study-on-importance-of-smartphones',
        type: 'Case Study',
        title: 'Case Study on the Importance of Smartphones',
        discipline: 'Research Writing',
        format: 'Harvard',
        sources: 4,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 9,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This paper is an example of a case study that discusses the importance of smartphones in today\'s life. This sample follows the APA citation guide and is an example of a properly formatted paper in APA',
        instructions: 'Case studies have become a key part of academic research and writing such document is one of the methods of active learning. For this assignment, find a contemporary topic or issue which you think needs further research. It can be a trend, or a phenomenon happening right now which directly affects a specific demographic. Note that case studies analyze facts, not hypothetical scenarios. Review the research methods and find one that fits your research question. Finally, offer your insights or recommendation to address the case - what could be done?',
        fileName: 'sample_16',
        buttonText: 'Order'
    },
    {
        id: 17,
        slug: 'critical-analysis-essay-on-invictus',
        type: 'Essay',
        title: 'Critical Analysis on William Ernest Henley’s Invictus ',
        discipline: 'English Composition',
        format: 'APA',
        sources: 1,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This paper sample is an example of a critical analysis essay. If you\'re looking for a reference to complete your critical analysis, check out this analysis of William Ernest Henley\'s "Invictus"',
        instructions: 'A critical thinking essay requires an individual to extensively analyze an object or idea. For students that need to write a critical thinking essay, you should choose a topic that you are knowledgeable about. Writing about poems and other written works will require you to read between lines, identify patterns, and decrypt symbols. You can use a point-by-point structure to discuss your analysis of different points.',
        fileName: 'sample_17',
        buttonText: 'Order'
    },
    {
        id: 18,
        slug: 'the-root-cause-of-climate-change',
        type: 'Essay',
        title: 'Critical Analysis of Climate Change',
        discipline: 'English Composition',
        format: 'MLA',
        sources: 3,
        level: {
            value: 'highschool',
            text: 'High School'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Looking for a sample of a critical analysis essay? Check out this analysis of the cause of climate change to use as a reference for your A+ critical analysis assignment',
        instructions: 'Write a critical thinking essay about a social issue by discussing the factors that caused it and society’s different perceptions toward the topic. Make sure to support your arguments through the use of credible sources. Since a critical thinking essay requires you to analyze an idea, provide an objective opinion based on factual evidence.',
        fileName: 'sample_18',
        buttonText: 'Order'
    },
    {
        id: 19,
        slug: 'should-criminals-suffer-more-in-prison',
        type: 'Essay',
        title: 'Persuasive Essay on Criminal Justice',
        discipline: 'English Composition',
        format: 'Turabian',
        sources: 3,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 6,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Looking for a sample of a persuasive essay? Or need an example of a good Criminal Justice paper? Check out this persuasive essay on why criminals deserve to suffer in prisons',
        instructions: 'Write a persuasive essay about crime and imprisonment by identifying issues and theories surrounding the subjects. Find documents that discuss the topic and can provide facts and evidence. With these facts, develop an argument regarding your position on the topic and use persuasive language to convince the readers.',
        fileName: 'sample_19',
        buttonText: 'Order'
    },
    {
        id: 20,
        slug: 'was-cyberpunk-2077-marketing-style-criminally-misleading',
        type: 'Essay',
        title: 'Persuasive Essay on Cyberpunk 2077\'s Marketing',
        discipline: 'Business Ethics',
        format: 'Harvard',
        sources: 4,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 8,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Have a persuasive essay due but don\'t know how to write a good one? Check out this persuasive essay sample on why the marketing of CD Projekt Red\'s Cyberpunk 2077 was criminally misleading',
        instructions: 'A persuasive essay requires an individual to convince the reader through creative and passionate language. Find an organization (business of non-profit) and analyze the ethics of that organization\'s marketing practices. Utilize a point-by-point structure to effectively discuss your ideas and arguments.',
        fileName: 'sample_20',
        buttonText: 'Order'
    },
    {
        id: 21,
        slug: 'analysis-on-the-causes-of-homelessness',
        type: 'Essay',
        title: 'Analytical Essay on the Causes of Homelessness in the US',
        discipline: 'English Composition',
        format: 'APA',
        sources: 5,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 6,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This paper is a sample of an analytical essay you may use for reference when working on your assignment. The example provides an analysis of the causes of homelessness in the US',
        instructions: 'Research social issues and gather authoritative sources that you can use for the paper. Establish an educated argument using the sources and discuss the various points that support the paper. When stating ideas or facts from another source, observe proper citation formats. The points that you will discuss must contain objective analysis regarding the topic and provide substantial information for the reader.',
        fileName: 'sample_21',
        buttonText: 'Order'
    },
    {
        id: 22,
        slug: 'time-and-memory-in-one-hundred-years-of-solitude',
        type: 'Essay',
        title: 'Analytical Essay on Marquez\'s One Hundred Years of Solitude',
        discipline: 'Book Report',
        format: 'MLA',
        sources: 1,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 5,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'This paper is a sample of an analytical essay you may use for reference when working on your Literature assignment. The example provides an analysis of the time and memory in the One Hundred Years of Solitude by G. G. Marquez',
        instructions: 'Analyze a piece of literature by identifying symbols, patterns, and the author’s use of literary devices. Compare people, objects, and events in the literature with the real world and analyze the author’s initial message. Make sure to provide appropriate evidences from your choice of literature to support your theories.',
        fileName: 'sample_22',
        buttonText: 'Order'
    },
    {
        id: 23,
        slug: 'swot-analysis-on-an-animal-shelter-business',
        type: 'Research Paper',
        title: 'SWOT Analysis for an Animal Shelter Business',
        discipline: 'Business',
        format: 'Harvard',
        sources: 3,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 8,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Need an example of a SWOT analysis paper for your Business Studies class? Check out this sample paper that provides a SWOT analysis for opening an animal shelter',
        instructions: 'Create a hypothetical business and develop background information regarding its business plan and operations. In the paper, introduce the business and its overall operations. Discuss its strengths, weaknesses, opportunities, and threats. For the strengths and weaknesses, you may use hypothetical data regarding the internal business factors. For threats and opportunities, try to utilize reliable sources that provide information regarding the industry. Write a conclusion that assesses the business’s condition based on the analysis.',
        fileName: 'sample_23',
        buttonText: 'Order'
    },
    {
        id: 24,
        slug: 'porters-five-forces-analysis-on-a-video-game-company',
        type: 'Research Paper',
        title: 'Porter’s Five Forces Analysis on a Video Game Company ',
        discipline: 'Business',
        format: 'APA',
        sources: 4,
        level: {
            value: 'College',
            text: 'College'
        },
        pages: 10,
        list: [
            'True professional full-time ghostwriting service writers (all native speakers)',
            '24/7 support & direct contact with a writer for your ghostwriting service assignment',
            '100% confidentiality & money-back guarantee'
        ],
        description: 'Need an example of a Porter’s Five Forces analysis paper for your class? Check out this sample paper that provides the Porter’s Five Forces analysis of a video game company ',
        instructions: 'Create a hypothetical business including its products, services, and operations.  Then, conduct research regarding the current condition in the industry. Identify the bargaining power of buyers and suppliers, barriers to entry, potential substitutes, and the nature of the industry’s competition. Include clear examples to show how the different forces affect the business. Use up-to-date authoritative sources to support your points. Utilize statistics and other market research to better illustrate the five forces.',
        fileName: 'sample_24',
        buttonText: 'Order'
    }
]