import  React, { useState, useEffect } from "react"

//COMPONENTS
import Layout from "@components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "@components/Breadcrumbs"
// import SamplesImage from "../../images/samples-step.svg"
// import BigTrial from "../../components/banners/BigTrial"
import BottomBanner from "@components/BottomBanner";

//DATA
import { samples } from '@content/samples'

const SamplesPage = () => {
    const steps= [
        {
            name: 'Samples',
            path: '/info/samples'
        }
    ]
    const metaInfo = {
        title: 'Free Essay Examples Online',
        description: 'FREE Online Essay Examples Database ✅ Help you with your Argumentative, Persuasive, Narrative, Descriptive, Compare and Contrast Essay or Research Paper ⚡ Big Database of Free Academic Papers' 
    }

    const Categories = samples.reduce((acc, {type}) => {
        acc['All samples'] = samples.length
        acc[type] = (acc[type] || 0) + 1;
        return acc
    }, {})

    const [filter, setFilter] = useState('All samples')
    const [samplesFiltered, setSamplesFiltered] = useState(samples)

    useEffect(() => {
        if (filter === 'All samples') {
            setSamplesFiltered(samples)
            return
        }
        setSamplesFiltered([
            ...samples.filter(({type}) => filter === type)
        ])
    }, [filter])

    return (
        <Layout>            
            <Seo {...metaInfo}></Seo>
            <div className="info-intro">
                <div className="wrapper">
                    <div className="info-intro--samples">
                        <div className="info-intro--samples-content">
                            <Breadcrumbs steps={steps}/>
                            <h1 className="page-intro__title">Start Your Paper with <span>Our Samples</span></h1>
                            <p className="info-intro--subtitle">
                                Thought provoking free example essays and term papers may fuel your writing efforts and inspire you to work on your own paper.
                            </p>
                        </div>
                        {/* <div className="info-intro--samples-img">
                            <img src={SamplesImage} alt="Samples" width={304} height={384} />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="samples-categories">
                <div className="wrapper">
                    <div className="samples-categories--title">Categories</div>
                    <div className="samples-categories--list">
                        {Object.entries(Categories).map((item) => {
                            return (
                                <div
                                    className={filter === item[0] ? "samples-categories--item active" : "samples-categories--item" }
                                    role="presentation"
                                    onClick={() => setFilter(item[0])}
                                >
                                    {item[0]}
                                    <span className="samples-categories--item-count">
                                        {item[1]}
                                    </span>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>
            <div className="samples-samples wrapper">
                {samplesFiltered.map((item, index) => {
                    return (
                        <div key={index} className="samples">
                            <p className="samples_title">
                                { item.type }
                            </p>
                            <div className="samples_body">
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Title of Piece:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.title }
                                    </p>
                                </div>
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Discipline:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.discipline }
                                    </p>
                                </div>
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Academic level:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.level.text }
                                    </p>
                                </div>
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Pages:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.pages }
                                    </p>
                                </div>
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Sources:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.sources }
                                    </p>
                                </div>
                                <div className="samples_body_col">
                                    <p className="samples_body_title">
                                        Paper format:
                                    </p>
                                    <p className="samples_body_desc">
                                        { item.format }
                                    </p>
                                </div>
                                <div className="samples_body_col samples_footer bottom-btn mb-0 bottom-col">
                                    <a href={`/sample/${item.slug}`} className="button button-primary" target="_blank" rel="noreferrer noopener">
                                        <span className="eye_icon"></span>
                                        View
                                    </a>
                                </div>
                                <div className="samples_body_col samples_footer bottom-btn mb-0 bottom-col">
                                    <a href={`/pdf/${item.fileName}.pdf`} download className="button button-border__main">
                                        <span className="download_icon"></span>
                                        Download
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <BigTrial /> */}
            <BottomBanner/>
            
        </Layout>
    )
}

export default SamplesPage